import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      screenshotImage: file(relativePath: { eq: "pixel-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 1256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <div className="flex flex-wrap w-full">
        <div className="flex-grow md:max-w-md md:self-center">
          <div className="flex flex-col">
            <div className="text-3xl text-center md:text-left md:text-4xl font-black large-slide-right">
              Quick answers for things to do with your bored kids.
            </div>
            <div className="text-xl text-center md:text-left md:text-xl text-gray-600 mt-2 large-slide-right">
              Over 100 responses to that dreaded phrase &quot;I'm bored!&quot;.
            </div>
            <div className="mt-8 self-center md:self-start medium-slide-right">
              <a href="https://play.google.com/store/apps/details?id=com.oddinteractive.imbored&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  style={{ width: 200 }}
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex-grow large-slide-left">
          <Img
            fluid={data.screenshotImage.childImageSharp.fluid}
            alt="Screenshot"
          />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
